body {
  margin: 0;
  padding: 0;
  font-family: 'Playfair Display', serif, 'Afrah','Noe Display Medium';
  background-color: #FAF8F0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
@font-face {
  font-family: 'Afrah';
  src: url('../public/fonts/AfrahLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Noe Display Medium';
  src: url('../public/fonts/FontsFree-Net-Noe-Display-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'VenturisADFCd-Regular';
  src: url('../public/fonts/VenturisADFCd-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Raisonne-demibold';
  src: url('../public/fonts/raisonne-demibold.ttf') format('truetype');
}

@font-face {
  font-family: 'PlaakBold';
  src: url('../public/fonts/PlaakBold.otf') format('opentype');
}